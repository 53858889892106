import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvGroup, AvInput, AvField } from "availity-reactstrap-validation"

import { postUser } from "networking/api"

class UserCreate extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: '',
      loading: false
    }

    this.submitForm.bind(this)
  }

  submitForm = async (event, data) => {
    this.setState({ loading: true })

    console.log(data);

    try {
      await postUser(data)
      this.props.history.push("/users")
    } catch(error) {
      console.error(error)
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
              Create User - Creative Display Content Request System
            </title>
          </MetaTags>
          <Container fluid>
          <Row>
              <Col xs={12}>
                <div className="page-title-box">
                  <h4 className="mb-0 font-size-18">New User</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.submitForm}>
                      {this.props.error && this.props.error ? (
                        <Alert color="danger">{this.props.error}</Alert>
                      ) : null}

                      <AvGroup className="mb-4" row>
                        <Label
                          for="firstName"
                          className="col-form-label col-lg-2"
                        >
                          First Name
                        </Label>
                        <Col lg="10">
                          <AvInput
                            id="firstName"
                            name="firstName"
                            type="text"
                            className="form-control"
                            placeholder="Enter a first name"
                            required
                          />
                        </Col>
                      </AvGroup>

                      <AvGroup className="mb-4" row>
                        <Label
                          for="lastName"
                          className="col-form-label col-lg-2"
                        >
                          Last Name
                        </Label>
                        <Col lg="10">
                          <AvInput
                            id="lastName"
                            name="lastName"
                            type="text"
                            className="form-control"
                            placeholder="Enter a last name"
                            required
                          />
                        </Col>
                      </AvGroup>

                      <AvGroup className="mb-4" row>
                        <Label
                          for="emailAddress"
                          className="col-form-label col-lg-2"
                        >
                          Email Address
                        </Label>
                        <Col lg="10">
                          <AvInput
                            id="emailAddress"
                            name="email"
                            type="text"
                            className="form-control"
                            placeholder="Enter an email address"
                            required
                          />
                        </Col>
                      </AvGroup>

                      <AvGroup className="mb-4" row>
                        <Label
                          for="department"
                          className="col-form-label col-lg-2"
                        >
                          Department
                        </Label>
                        <Col lg="10">
                          <AvField 
                            type="select" 
                            name="department"
                            id="department"
                            value="creative"
                          >
                            <option value="creative" selected>Creative</option>
                            <option value="sales">Sales</option>
                            <option value="none">None</option>
                          </AvField>
                        </Col>
                      </AvGroup>

                      <AvGroup className="mb-4" row>
                        <Label
                          for="role"
                          className="col-form-label col-lg-2"
                        >
                          Role
                        </Label>
                        <Col lg="10">
                          <AvField 
                            type="select" 
                            name="role"
                            id="role"
                            value="user"
                          >
                            <option value="user" selected>User</option>
                            <option value="admin">Administrator</option>
                          </AvField>
                        </Col>
                      </AvGroup> 

                      <AvGroup className="mb-4" row>
                        <Col lg="2"></Col>
                        <Col lg="10">
                          <Button color="primary" disabled={this.state.loading}>
                            Send Invite
                          </Button>
                        </Col>  
                      </AvGroup>                                    
                    </AvForm>                    
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

UserCreate.propTypes = {
  history: PropTypes.object
}

export default withRouter(UserCreate)
