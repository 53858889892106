import React, { Component } from "react"
import MetaTags from "react-meta-tags"
import { Col, Container, Row } from "reactstrap"

export default class InvitationError extends Component {
  render() {
    return (
      <React.Fragment>
        <div>
          <MetaTags>
            <title>
              Invitation Error - Creative Display Content Request System
            </title>
          </MetaTags>
          <Container fluid className="p-0">
            <Row className="g-0">
              <Col cl={3}>
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="my-auto">
                        <div className="text-center">
                          <div className="avatar-md mx-auto">
                            <div className="avatar-title rounded-circle bg-light">
                              <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                            </div>
                          </div>
                          <div className="p-2 mt-4">
                            <h4>Uh-oh...</h4>
                            <p>
                              The invitation you are attempting to redeem does not appear to be valid.
                            </p>
                            <p>
                              Please conctact a system administrator to request a new invitation to be sent.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          © {new Date().getFullYear()} Creative Display Technologies
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
