import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import EnterPwdReset from "../pages/Authentication/EnterPasswordReset"
import InvitationError from "../pages/Authentication/InvitationError"
import InvitationAccept from "../pages/Authentication/InvitationAccept"

// Guest Preview
import GuestPreview from "../pages/GuestPreview/index"

// Locations
import LocationList from "../pages/Locations/list"
import LocationCreate from "../pages/Locations/create"

// Users
import UserList from "../pages/Users/list"
import UserCreate from "../pages/Users/create"

// Work Orders
import WorkOrderList from "../pages/WorkOrders/list"
import WorkOrderOverview from "../pages/WorkOrders/Overview/overview"
import WorkOrderCreate from "../pages/WorkOrders/create"
import WorkOrderEdit from "../pages/WorkOrders/edit"

const authProtectedRoutes = [
  
  //Work Orders
  { path: "/work-orders", component: WorkOrderList },
  { path: "/work-orders/new", component: WorkOrderCreate },
  { path: "/work-orders/:id/edit", component: WorkOrderEdit },
  { path: "/work-orders/:id", component: WorkOrderOverview },
  
  // Locations
  { path: "/locations", component: LocationList },
  { path: "/locations/new", component: LocationCreate },

  // Users
  { path: "/users", component: UserList },
  { path: "/users/new", component: UserCreate },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/work-orders" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password/:id", component: EnterPwdReset },
  { path: "/invitations/error", component: InvitationError },
  { path: "/invitations/:id", component: InvitationAccept },
  { path: "/previews/:id", component: GuestPreview }
]

export { authProtectedRoutes, publicRoutes }
