// AUTH
export const LOGIN = "/auth/login"
export const FORGOT_PASSWORD = "/auth/reset-password"

// WORK ORDERS
export const WORKORDERS = "/work-orders"

// LOCATIONS
export const LOCATIONS = "/locations"

// USERS
export const USERS = "/users"

// INVITATIONS
export const INVITATIONS = "/users/invitations"

// PREVIEWS
export const PREVIEWS = "/previews"

// PROFILE
export const PROFILE = "/profile"
