import React, { Component } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { getPreview } from "networking/api"

class GuestPreview extends Component {
  constructor(props) {
    super(props)

    this.state = {
      preview: {}
    }
  }

  componentDidMount() {
    this.loadPreview()
  }

  loadPreview = async () => {
    try {
      const preview = await getPreview(this.props.match.params.id)
      this.setState({ preview })
    } catch(error) {
      console.error(error)
    }
  }

  getComponentForPreview = (preview) => {
    const images = ['gif', 'png', 'jpg', 'jpeg', 'bmp']
    const videos = ['mp4', 'mov', 'avi']
    const binary = ['pdf']

    const ext =  preview.name.split('.').pop();

    if(images.includes(ext)) {
      return (<img src={preview.link} style={{width: '100%'}} />)
    } else if(videos.includes(ext)) {
      return (
        <video controls style={{width: '100%'}}>Your browser does not support the &lt;video&gt; tag.
          <source src={preview.link} />
        </video>
      )
    } else if(binary.includes(ext)) {
      return (<p>Click <a href={preview.link} target="_blank">here</a> to download.</p>)
    } else {
      return (<p>This file type is not supported by the system.</p>)
    }
  }

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>
            Guest Preview - Creative Display Content Request System
          </title>
        </MetaTags>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col xl={12}>
                <Card className="overflow-hidden">
                  <CardBody className="pt-0">
                    <div className="p-2" style={{'marginTop': '1.5rem'}}>
                      { this.state.preview.link == undefined
                        ? "Loading..."
                        : (this.getComponentForPreview(this.state.preview))
                      }
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

GuestPreview.propTypes = {
  history: PropTypes.object
}

export default withRouter(GuestPreview)
