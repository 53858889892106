import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"

import AddComment from "./addComment"
import FileUpload from "./fileUpload"

class UpdateStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      statusDropdownOpen: false,
      fileUploadNeeded: false,
      commentNeeded: false,
      pendingStatus: ''
    }
  }

  handleStatusSubmit = (status) => {
    switch (status) {
      case "revision":
        this.setState({ 
          commentNeeded: true,
          fileUploadNeeded: false,
          pendingStatus: status
        })
        break

      case "artwork_ready":
        this.setState({
          commentNeeded: false,
          fileUploadNeeded: true,
          pendingStatus: status
        })
        break

      default:
        this.setState({
          commentNeeded: false,
          fileUploadNeeded: false
        })
        this.props.statusSelected({ status })
        break
    }
  }

  handleCommentSubmit = async (comment) => {
    this.props.statusSelected({
      status: this.state.pendingStatus,
      notes: comment
    })
  }

  handleAcceptedFiles = (files) => {
    this.props.statusSelected({
      status: this.state.pendingStatus,
      files: files
    })
  }
  
  render() {
    const isCommentNeeeded = this.state.commentNeeded
    const isFileUploadNeeded = this.state.fileUploadNeeded

    return (
      <React.Fragment>
        {!isCommentNeeeded && !isFileUploadNeeded &&
          <>
            <p>
              Please select a status from the list below. Be aware that some statuses require multiple steps to set.
            </p>
            <Dropdown
              container="body"
              isOpen={this.state.statusDropdownOpen}
              toggle={() =>
                this.setState({ statusDropdownOpen: !this.state.statusDropdownOpen })
              }
            >
              <DropdownToggle className="btn btn-secondary" caret>
                Choose a Status{" "}
                <i className="mdi mdi-chevron-down"></i>
              </DropdownToggle>
              <DropdownMenu>
                {
                  this.props.statuses.map(function(status) {
                    return <DropdownItem key={`status-item-${status.value}`} onClick={() => this.handleStatusSubmit(status.value)}>
                      {status.title}
                    </DropdownItem>
                  }, this)
                }
              </DropdownMenu>
            </Dropdown>
          </>
        }

        {isCommentNeeeded &&
          <>
            <h2>Revision</h2>
            <p>This status requires additional instructions or reasoning. Please be descriptive.</p>
            <AddComment callback={this.handleCommentSubmit} />
          </>
        }
        {isFileUploadNeeded && 
          <>
            <h5>Artwork Ready</h5>
            <p>This status requires the requested resource to be uploaded.</p>
            <FileUpload onFilesSelected={this.handleAcceptedFiles} />
          </>
        }
      </React.Fragment>
    )
  }
}

UpdateStatus.propTypes = {
  statuses: PropTypes.array,
  statusSelected: PropTypes.func,
}

export default UpdateStatus
