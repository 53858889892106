import axios from "axios"
import { removeUser } from "helpers/user_helper"

const axiosApi = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_SERVICE_URL,
})

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => { 
    if(error.response.status === 401) {
      removeUser()
      window.location = '/login'
    } else {
      const apiError = error.response.data.error
      return Promise.reject(apiError ?? error.toString())
    }
  }
)

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data)
}

export async function patch(url, data = {}, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then((response) => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data)
}

export async function multipart(url, formData, config = {}) {
  return axiosApi
    .post(url, formData, { ...config })
    .then((response) => response.data)
}
