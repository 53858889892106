import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { Card, CardBody, Col, Media, Row } from "reactstrap"
import Moment from "react-moment"
import statusMap from "../statusColorMap"

const ProjectDetail = ({ project }) => {
  return (
    <Card>
      <CardBody>
        <Media className="overflow-hidden" body>
          <h4 className="text-truncate">{project.title}</h4>
          <span className={`badge badge-soft-${statusMap(project.status)}`} style={{marginBottom: '10px'}}>{project.status.replace(/_/g, ' ')}</span>
        </Media>

        <h5 className="font-size-15 mt-4">Project Details :</h5>

        <p className="text-muted">
          { (project.details === "") ?
            "No details were added to this work order." :
            project.details  
          }
        </p>

        <Row className="task-dates">
          <Col sm="4" xs="6">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar-check me-1 text-primary" /> Due
                Date
              </h5>
              <Moment format="MMMM DD YYYY" className="text-muted mb-0">{project.dueAt}</Moment>
            </div>
          </Col>
          <Col sm="4" xs="6">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar me-1 text-primary" /> Start Date
              </h5>
              <Moment format="MMMM DD YYYY" className="text-muted mb-0">{project.startsAt}</Moment>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

ProjectDetail.propTypes = {
  project: PropTypes.object,
}

export default ProjectDetail
