import React from "react"
import { Link } from "react-router-dom"

const contactListColumns = () => [
  {
    text: "Name",
    dataField: "name",
    sort: true,
    formatter: (cellContent, location) => (
      <>
        <h5 className="font-size-14 mb-1">
          <Link to="#" className="text-dark">
            {location.name}
          </Link>
        </h5>
        <p className="text-muted mb-0">{location.region}</p>
      </>
    ),
  }
]

export default contactListColumns
