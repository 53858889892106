import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
} from "./actionTypes"

export const userForgotPasswordAction = (user, history) => {
  return {
    type: FORGOT_PASSWORD,
    payload: { user, history },
  }
}

export const userForgotPasswordSuccess = (message) => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userForgotPasswordError = (message) => {
  return {
    type: FORGOT_PASSWORD_ERROR,
    payload: message,
  }
}
