const statusMap = (status) => {
  switch(status.replace(/_/g, ' ')) {
    case 'revision': return 'danger'
    case 'approved': return 'success'
    case 'in progress': return 'primary'
    default: return 'dark'
  }
}

export default statusMap
