import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { isEmpty } from "lodash"
import { Col, Container, Modal, Row } from "reactstrap"

import ProjectDetail from "./projectDetail"
import TeamMembers from "./teamMembers"
import AttachedFiles from "./attachedFiles"
import Comments from "./comments"
import LocationList from "./locationList"
import FileUpload from "../Reusable/fileUpload"
import Previews from "./previews"
import UpdateStatus from "../Reusable/updateStatus"
import availableStatusMap from "../availableStatusMap"

import { getWorkOrder, postStatus, postComment, postFiles, postPreview, patchAssignWorkOrder } from "../../../networking/api"

class WorkOrderOverview extends Component {
  constructor(props) {
    super(props)

    this.state = {
      workOrder: {},
      fileUploadShown: false,
      updateStatusShown: false
    }
  }

  componentDidMount() {
    const {
      match: { params: { id } },
    } = this.props

    this.loadData(id)
  }

  loadData = async(id) => {
    try {
      const workOrder = await getWorkOrder(id)
      this.setState({ workOrder })
    } catch(error) {
      console.error(error)
    }
  }

  generateStatusList = () => {
    return availableStatusMap(this.state.workOrder)
  }

  generateMembers = () => {
    const { workOrder } = this.state

    var team = []

    team.push({
      firstName: workOrder.submittedBy.firstName,
      lastName: workOrder.submittedBy.lastName,
      department: workOrder.submittedBy.department
    })

    if(workOrder.assignedTo) {
      team.push({
        firstName: workOrder.assignedTo.firstName,
        lastName: workOrder.assignedTo.lastName,
        department: workOrder.assignedTo.department
      })
    }

    return team
  }

  generateLocationList = () => {
    const { workOrder } = this.state

    if(workOrder) {
      return workOrder.locations.map(function(location) {
        return { name: location.name }
      })
    } else {
      return []
    }
  }

  handleFileUpload = async (files) => {
    const data = {
      workOrderId: this.props.match.params.id,
      files: files
    }

    try {
      await postFiles(data)
      this.props.history.go(0)
    } catch (error) {
      console.error(error)
    }
  }

  handleCommentSubmit = async(comment) => {
    try {
      await postComment({ id: this.state.workOrder._id, comment })
      this.props.history.go(0)
    } catch(error) {
      console.error(error)
    }
  }

  handleUpdateStatus = async (status) => {
    try {
      await postStatus(this.state.workOrder._id, status)
      this.props.history.go(0)
    } catch(error) {
      console.error(error)
    }
  }

  closeFileUpload = () => {
    this.setState({
      fileUploadShown: false
    })
  }

  closeUpdateStatus = () => {
    this.setState({
      updateStatusShown: false
    })
  }

  selfAssign = async () => {
    try {
      await patchAssignWorkOrder(this.props.match.params.id)
      this.props.history.go(0)
    } catch(error) {
      console.error(error)
    }
  }

  handlePreviewSend = async (data) => {
    try {
      await postPreview(data)
      this.props.history.go(0)
    } catch(error) {
      console.error(error)
    }
  }

  render() {
    const { workOrder } = this.state

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
              Work Order Details - Creative Display Content Request System
            </title>
          </MetaTags>
          <Container fluid>
            <Row>
              <Col xs={12}>
                <div className="page-title-box">
                  <h4 className="mb-0 font-size-18">Work Order Details</h4>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <div style={{marginBottom: '30px'}} className="text-end">
                  {
                    !workOrder.assignedTo &&
                    <button className="btn btn-outline-primary me-2" onClick={this.selfAssign}>
                      Assign to me
                    </button>
                  }
                  <button className="btn btn-outline-primary me-2" onClick={() => this.setState({ updateStatusShown: true })}>
                    Update status
                  </button>
                  <Link to={`${this.props.match.params.id}/edit`} className="btn btn-outline-primary">
                    Edit
                  </Link>
                </div>                
              </Col>
            </Row>

            {!isEmpty(workOrder) && (
              <>
                <Row>
                  <Col lg="8">
                    <Row>
                      <Col lg="12"><ProjectDetail project={workOrder} /></Col>
                    </Row>
                    {
                      (workOrder.status == 'artwork_ready' || workOrder.status == 'approved') && (
                        <Row>
                          <Col lg="12"><Previews previews={workOrder.previews} onSend={this.handlePreviewSend} /></Col>
                        </Row> 
                      )
                    }
                    <Row>
                      <Col lg="12"><Comments comments={workOrder.comments} callback={this.handleCommentSubmit} /></Col>
                    </Row>       
                  </Col>

                  <Col lg="4">
                    <Row>
                      <Col lg="12">
                        <TeamMembers selfAssign={this.selfAssignWorkOrder} team={this.generateMembers()} />
                      </Col>
                      <Col lg="12">
                        <LocationList locations={this.generateLocationList()} />
                      </Col>
                      <Col lg="12">
                        <AttachedFiles 
                          files={workOrder.resources} 
                          openFileUpload={ () => this.setState({ fileUploadShown: true }) } 
                        />
                      </Col>
                    </Row>                    
                  </Col>                  
                </Row>
              </>
            )}
          </Container>
        </div>

        <Modal
          isOpen={this.state.fileUploadShown}
          toggle={this.closeFileUpload}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">File Upload</h5>
            <button
              type="button"
              onClick={this.closeFileUpload}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <FileUpload onFilesSelected={this.handleFileUpload} />
          </div>
        </Modal>

        <Modal
          isOpen={this.state.updateStatusShown}
          toggle={this.closeUpdateStatus}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Update Status</h5>
            <button
              type="button"
              onClick={this.closeUpdateStatus}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <UpdateStatus statuses={this.generateStatusList()} statusSelected={this.handleUpdateStatus} />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

WorkOrderOverview.propTypes = {
  match: PropTypes.object
}

export default withRouter(WorkOrderOverview)
