import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import userListColumns from "./userListColumns"
import { isEmpty, size } from "lodash"

import { getUsers, deleteUser, patchUserEnabled } from "networking/api"

class UserList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      tableUsers: []
    }
  }

  componentDidMount() {
    this.loadData()
  }

  loadData = async() => {
    try {
      const response = await getUsers()
      this.setState({ users: response, tableUsers: response })
    } catch(error) {
      console.error(error)
    }
  }

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (type, { page, searchText }) => {
    const { users } = this.state
    this.setState({
      tableUsers: users.filter((user) =>
        Object.keys(user).some(
          (key) =>
            typeof user[key] === "string" &&
            user[key].toLowerCase().includes(searchText.toLowerCase())
        )
      ),
    })
  };

  handleUserEnableStatusUpdate = async (user) => {
    try {
      await patchUserEnabled({
        id: user._id,
        enabled: !user.isActive
      })

      this.props.history.go(0)
    } catch(error) {
      console.error(error)
    }
  }

  handleDeleteUser = async (user) => {
    try {
      await deleteUser(user._id)
      this.props.history.go(0)
    } catch(error) {
      console.error(error)
    }
  }

  render() {
    const { tableUsers } = this.state
    const { SearchBar } = Search

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
              Users - Creative Display Content Request System
            </title>
          </MetaTags>
          <Container fluid>
            <Row>
              <Col xs={12}>
                <div className="page-title-box">
                  <h4 className="mb-0 font-size-18">Users</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>                    
                    <ToolkitProvider
                      keyField="id"
                      data={tableUsers}
                      columns={userListColumns(this.handleUserEnableStatusUpdate, this.handleDeleteUser)}
                      bootstrap4
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col xs="8">
                              <div className="search-box ms-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col className="text-end">
                              <Link to="/users/new" className="btn btn-outline-primary">
                                New User
                              </Link>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  responsive
                                  remote
                                  bordered={false}
                                  striped={false}
                                  classes={
                                    "table align-middle table-nowrap table-hover"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                  onTableChange={this.handleTableChange}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>                      
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

UserList.propTypes = {
  history: PropTypes.object
}

export default withRouter(UserList)
