import { takeEvery, put, call } from "redux-saga/effects"

// Login Redux States
import { FORGOT_PASSWORD } from "./actionTypes"
import { userForgotPasswordSuccess, userForgotPasswordError } from "./actions"

//Include Both Helper File with needed methods
import {
  forgotPassword,
} from "networking/api"


function* userForgotPassword({ payload: { user, history } }) {
  try {
    const response = yield call(forgotPassword, { email: user.email })
      yield put(
        userForgotPasswordSuccess(
          "Reset link are sended to your mailbox, check there first"
        )
      )
  } catch (error) {
    yield put(userForgotPasswordError(error))
  }
}

function* forgotPasswordSaga() {
  yield takeEvery(FORGOT_PASSWORD, userForgotPassword)
}

export default forgotPasswordSaga
