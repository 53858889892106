import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardTitle, Table } from "reactstrap"
import { Link } from "react-router-dom"
import { map } from "lodash"

const AttachedFiles = ({ files, openFileUpload }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">
          Attached Files 
        </CardTitle>
        <div className="table-responsive" style={{ marginBottom: '30px' }}>
          <Table className="table align-middle table-hover mb-0">
            <tbody>
              {map(files, (file, i) => (
                <tr key={"_file_" + i}>
                  <td style={{ width: "45px" }}>
                    <div className="avatar-sm">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-24">
                        <i className="bx bxs-file" />
                      </span>
                    </div>
                  </td>
                  <td>
                    <h5 className="font-size-14 mb-1">
                      <Link to="#" className="text-dark">
                        {file.name}
                      </Link>
                    </h5>
                  </td>
                  <td>
                    <div className="text-center">
                      <a href={file.link} className="text-dark" target="_blank">
                        <i className="bx bx-download h3 m-0" />
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <button className="btn btn-primary" onClick={openFileUpload}>Upload New Files</button>
      </CardBody>
    </Card>
  )
}

AttachedFiles.propTypes = {
  files: PropTypes.array,
}

export default AttachedFiles
