import { del, get, patch, post, multipart } from "./api_helper"
import * as url from "../helpers/url_helper"

export const login = async (data) => await post(url.LOGIN, data)
export const forgotPassword = async (data) => await post(url.FORGOT_PASSWORD, data)
export const forgotPasswordVerification = async (id) => await get(`${url.FORGOT_PASSWORD}/${id}/verify`)
export const forgotPasswordComplete = async (data) => await post(`${url.FORGOT_PASSWORD}/${data.id}/complete`, data)
export const editProfile = async (data) => await post(url.PROFILE, data)
export const getUsers = async () => await get(url.USERS)
export const postUser = async (data) => await post(url.INVITATIONS, data)
export const patchUserEnabled = async (data) => await patch(`${url.USERS}/${data.id}/enable`, data)
export const deleteUser = async (id) => await del(`${url.USERS}/${id}`)
export const getWorkOrders = async () => await get(url.WORKORDERS)
export const getWorkOrder = async (id) => await get(`${url.WORKORDERS}/${id}`)
export const postWorkOrder = async (data) => {
  let form = new FormData()

  for (const [key, value] of Object.entries(data)) {
    if (key.toLowerCase() != 'files' && key.toLowerCase() != 'locations') {
      form.append(key, value)
    }
  }

  if (data.locations.length > 0) {
    data.locations.forEach(location => {
      form.append('locations[]', location)
    })
  }

  if (data.files.length > 0) {
    data.files.forEach(file => {
      form.append('file', file, file.name)
    })
  }

  multipart(url.WORKORDERS, form)
}

export const postFiles = async (data) => {
  let form = new FormData()

  if (data.files.length > 0) {
    data.files.forEach(file => {
      form.append('file', file, file.name)
    })
  }

  await multipart(`${url.WORKORDERS}/${data.workOrderId}/files`, form)
}
export const getPreview = async (id) => await get(`${url.PREVIEWS}/${id}`)
export const postPreview = async (data) => await post(`${url.PREVIEWS}/send/${data.preview}`, data)
export const patchWorkOrder = async (id, data) => await patch(`${url.WORKORDERS}/${id}`, data)
export const getLocations = async () => await get(url.LOCATIONS)
export const getLocation = async (id) => await get(`${url.LOCATIONS}/${id}`)
export const postLocation = async (data) => await post(url.LOCATIONS, data)
export const patchAssignWorkOrder = async (id) => await patch(`${url.WORKORDERS}/${id}/assign`)
export const postComment = async (data) => await post(`${url.WORKORDERS}/${data.id}/comments`, { comment: data.comment })
export const postStatus = async (id, data) => {
  let form = new FormData()

  for (const [key, value] of Object.entries(data)) {
    if (key.toLowerCase() != 'files') {
      form.append(key, value)
    }
  }

  if (data.files != undefined && data.files.length > 0) {
    data.files.forEach(file => {
      form.append('file', file, file.name)
    })
  }

  await multipart(`${url.WORKORDERS}/${id}/status`, form)
}

export const postInvitationAccept = async (id, data) => await post(`${url.INVITATIONS}/${id}/accept`, data)
