import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardTitle, Media } from "reactstrap"
import { map } from "lodash"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import AddComment from "../Reusable/addComment"
import Moment from "react-moment"

const Comments = ({ comments, callback }) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Comments</CardTitle>
          { (comments.length == 0)
            ? <span className="text-muted">Nothing to see here... yet.</span>
            : map(comments, (comment, index) => (
              <Media className="mb-4" key={index}>
                <div className="me-3">
                  <div className="avatar-xs">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-12">
                      {comment.submittedBy.firstName.charAt(0)}{comment.submittedBy.lastName.charAt(0)}
                    </span>
                  </div>
                </div>
                <Media body>
                  <h5 className="font-size-14 mb-2">{comment.submittedBy.firstName} {comment.submittedBy.lastName}</h5>
                  <p className="text-muted mb-1">{comment.comment}</p>
                  <Moment format="MMMM DD YYYY" className="text-muted mb-0 font-size-10">{comment.createdAt}</Moment>
                </Media>
              </Media>
            ))
          }
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Add a Comment</CardTitle>
          <AddComment callback={callback} />
        </CardBody>
      </Card>
    </React.Fragment>    
  )
}

Comments.propTypes = {
  comments: PropTypes.array,
  callback: PropTypes.func
}

export default Comments
