import React, { Component } from "react"
import PropTypes from "prop-types"
import { Input } from "reactstrap"

class AddComment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
    }
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value })
  }

  save = () => {
    this.props.callback(this.state.value)
  }

  render() {
    const { editorState } = this.state;

    return (
      <React.Fragment>
        <Input type="textarea" value={this.state.value} onChange={this.handleChange} />        
        <button className="btn btn-primary" style={{marginTop: '20px'}} onClick={this.save}>Add Comment</button>
      </React.Fragment>
    )
  }
}

AddComment.propTypes = {
  callback: PropTypes.func,
}

export default AddComment
