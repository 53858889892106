import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { Component } from "react"
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"
import { forgotPasswordVerification, forgotPasswordComplete } from "networking/api"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// import images
import profile from "../../assets/images/profile-img.png"

class EnterPasswordResetPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initialLoadComplete: false,
      resetFailed: false
    }

    this.checkValidity()
  }

  checkValidity = async () => {
    try {
      const {
        match: { params: { id } },
      } = this.props
      await forgotPasswordVerification(id);
      this.setState({ initialLoadComplete: true })
    } catch (error) {
      this.props.history.push('/login')
    }
  }

  // handleValidSubmit
  handleValidSubmit = async (event, values) => {
    this.setState({ resetRequested: true })

    const {
      match: { params: { id } },
    } = this.props

    try {
      await forgotPasswordComplete({ id: id, password: values.password })
      this.props.history.push('/login')
    } catch (error) {
      this.setState({ resetFailed: true })
    }
  }

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>
            Password Reset - Creative Display Content Request System
          </title>
        </MetaTags>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            { this.state.initialLoadComplete && (
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                      <Row>
                        <Col className="col-7">
                          <div className="text-primary p-4">
                            <h5 className="text-primary">Set your new password</h5>
                            <p>Enter your new password to continue.</p>
                          </div>
                        </Col>
                        <Col className="col-5 align-self-end">
                          <img src={profile} alt="" className="img-fluid" />
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                    {this.state.resetFailed ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          Your password was not able to be updated. This may happen if the password reset window has expired. Please try again.
                        </Alert>
                      ) : null}
                      <div className="p-2" style={{'marginTop': '1.5rem'}}>
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={this.handleValidSubmit}
                        >
                          <div className="mb-3">
                            <AvField
                              name="password"
                              label="Password"
                              className="form-control"
                              placeholder="Enter your new password"
                              type="password"
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              name="passwordConfirmation"
                              label="Confirm your password"
                              className="form-control"
                              placeholder="Confirm your new password"
                              type="password"
                              validate={{match:{value:'password'}}}
                              required
                            />
                          </div>
                          <div className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              disabled={this.state.resetRequested}
                            >
                              Update Password
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-5 text-center">
                    <p>
                      Go back to{" "}
                      <a href="/login" className="fw-medium text-primary">
                        Login
                      </a>
                    </p>
                  </div>
                </Col>
              </Row>
            )}
            
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

EnterPasswordResetPage.propTypes = {
  history: PropTypes.object
}

export default EnterPasswordResetPage;
