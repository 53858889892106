import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { Component } from "react"
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { AvField, AvForm } from "availity-reactstrap-validation"
import profile from "../../assets/images/profile-img.png"
import { postInvitationAccept } from "networking/api" 

class InvitationAccept extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      loading: false
    }
  }

  handleValidSubmit = (event, values) => {
    if(values.password == values.passwordConfirmation) {
      this.performInvitationAcceptance({ 
        username: values.username, 
        password: values.password 
      })
    } else if (values.password.length > 0 && values.passwordConfirmation.length > 0)  {
      this.setState({
        error: "Password and Password Confirmation fields do not match."
      })
    }
  }

  performInvitationAcceptance = async (data) => {
    const id = this.props.match.params.id
    
    try {
      this.setState({ loading: true }, async () => {
        await postInvitationAccept(id, data)
        this.props.history.push("/login")
      })
    } catch (error) {
      this.setState({
        error: error,
        loading: false
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>
            Invitation - Creative Display Content Request System
          </title>
        </MetaTags>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Confirm your account</h5>
                          <p>Enter your new account username and password to continue.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="p-2" style={{'marginTop': '1.5rem'}}>
                      {this.state.error && this.state.error ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {this.state.error}
                        </Alert>
                      ) : null}

                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        <div className="mb-3">
                          <AvField
                            name="username"
                            label="Username"
                            className="form-control"
                            placeholder="Enter username"
                            type="text"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="password"
                            label="Password"
                            className="form-control"
                            placeholder="Enter password"
                            type="password"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="passwordConfirmation"
                            label="Password Confirmation"
                            className="form-control"
                            placeholder="Enter password again"
                            type="password"
                            required
                          />
                        </div>
                        <div className="text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            disabled={this.state.loading}
                            type="submit"
                          >
                            Create account
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

InvitationAccept.propTypes = {
  history: PropTypes.object
}

export default withRouter(InvitationAccept)
