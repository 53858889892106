import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { Link, withRouter } from "react-router-dom"
import { 
  UncontrolledAlert,
  Card, 
  CardBody, 
  Col, 
  Container,
  Modal, 
  Row 
} from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import workOrderListColumns from "./workOrderListColumns"
import AddComment from "./Reusable/addComment"
import UpdateStatus from "./Reusable/updateStatus"
import availableStatusMap from "./availableStatusMap"
import { getWorkOrders, patchAssignWorkOrder, postStatus, postComment } from "../../networking/api"

class WorkOrderList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      workOrders: [],
      tableWorkOrders: [],
      statusModalShown: false,
      statusDropdownOpen: false,
      commentModalShown: false,
      currentManageId: null,
      statusesForWorkOrder: []
    }
  }

  componentDidMount() {
    this.loadData()
  }

  loadData = async () => {
    try {
      const workOrders = await getWorkOrders()

      const sorted = workOrders.sort((l, r) => {
        var leftPriority = 9
        var rightPriority = 9

        if(l.status == "approved") { leftPriority = 0 }
        if(l.status == "revision") { leftPriority = 1 }
        if(l.status == "received") { leftPriority = 2 }
        if(l.status == "in_progress") { leftPriority = 3 }
        if(l.status == "artwork_ready") { leftPriority = 4 }
        if(l.status == "hold") { leftPriority = 5 }

        if(r.status == "approved") { rightPriority = 0 }
        if(r.status == "revision") { rightPriority = 1 }
        if(r.status == "received") { rightPriority = 2 }
        if(r.status == "in_progress") { rightPriority = 3 }
        if(r.status == "artwork_ready") { rightPriority = 4 }
        if(r.status == "hold") { rightPriority = 5 }

        return leftPriority > rightPriority
      })

      this.setState({ workOrders: sorted, tableWorkOrders: sorted })
    } catch(error) {
      console.error(error)
    }
  }

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (type, { page, searchText }) => {
    const { workOrders } = this.state
    this.setState({
      tableWorkOrders: workOrders.filter((workOrder) =>
        Object.keys(workOrder).some(
          (key) =>
            typeof workOrder[key] === "string" &&
            workOrder[key].toLowerCase().includes(searchText.toLowerCase())
        )
      ),
    })
  };

  toggleStatusModal = (workOrder) => {
    const statuses = availableStatusMap(workOrder)

    this.setState({
      statusModalShown: true,
      currentManageId: workOrder._id,
      statusesForWorkOrder: statuses
    })
  }

  showCommentModal = (workOrder) => {
    this.setState({
      commentModalShown: true,
      currentManageId: workOrder._id
    })
  }

  assign = async (workOrder) => {
    try {
      await patchAssignWorkOrder(workOrder._id)
      this.loadData()
    } catch(error) {
      console.error(error)
    }
  }

  resetQuickActionSettings = () => {
    this.setState({
      statusModalShown: false,
      commentModalShown: false,
      currentManageId: null
    })
  }

  handleStatusSubmit = async (status) => {
    try {
      await postStatus(this.state.currentManageId, status)
      this.props.history.go(0)
    } catch(error) {
      console.error(error)
    }
  }

  handleCommentSubmit = async (comment) => {
    try {
      await postComment({ id: this.state.currentManageId, comment })
      this.props.history.go(0)
    } catch(error) {
      console.error(error)
    }
  }

  render() {
    const { tableWorkOrders } = this.state
    const { SearchBar } = Search

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
              Work Orders - Creative Display Content System
            </title>
          </MetaTags>
          <Container fluid>
            {this.props.error ? 
              (<UncontrolledAlert color="danger">{this.props.error}</UncontrolledAlert>) : null
            }
            <Row>
              <Col xs={12}>
                <div className="page-title-box">
                  <h4 className="mb-0 font-size-18">Work Orders</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="_id"
                      data={tableWorkOrders}
                      columns={workOrderListColumns(this.toggleStatusModal, this.showCommentModal, this.assign)}
                      bootstrap4
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col xs="8">
                              <div className="search-box ms-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col className="text-end">
                              <Link to="/work-orders/new" className="btn btn-outline-primary">
                                Create New
                              </Link>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  responsive
                                  remote
                                  bordered={false}
                                  striped={false}
                                  classes={
                                    "table align-middle table-nowrap table-hover"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                  onTableChange={this.handleTableChange}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>                      
                  </CardBody>
                </Card>
                <Modal
                  isOpen={this.state.statusModalShown}
                  toggle={this.resetQuickActionSettings}
                  centered={true}
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">Update Status</h5>
                    <button
                      type="button"
                      onClick={this.resetQuickActionSettings}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <UpdateStatus statuses={this.state.statusesForWorkOrder} statusSelected={this.handleStatusSubmit} />
                  </div>
                </Modal>
                <Modal
                  isOpen={this.state.commentModalShown}
                  toggle={this.resetQuickActionSettings}
                  centered={true}
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">Add a Comment</h5>
                    <button
                      type="button"
                      onClick={this.resetQuickActionSettings}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <AddComment callback={this.handleCommentSubmit} />
                  </div>
                </Modal>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

WorkOrderList.propTypes = {
  history: PropTypes.object
}

export default withRouter(WorkOrderList)
