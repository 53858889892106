import React, { Component } from "react"
import PropTypes from "prop-types"
import Dropzone from "react-dropzone";

class FileUpload extends Component {
  render() {
    return (
      <Dropzone
        onDrop={ (acceptedFiles) => this.props.onFilesSelected(acceptedFiles) }
      >
        {({ getRootProps, getInputProps }) => (
        <div className="dropzone">
          <div
            className="dz-message needsclick"
            {...getRootProps()}
          >
          <input {...getInputProps()} />
          <div className="dz-message needsclick">
            <div className="mb-3">
              <i className="display-4 text-muted bx bxs-cloud-upload" />
            </div>
            <h4>Drop files here or click to upload.</h4>
          </div>
          </div>
        </div>
        )}
      </Dropzone>
    )
  }
}

FileUpload.propTypes = {
  onFilesSelected: PropTypes.func,
}

export default FileUpload
