import React from "react"
import { Link } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"
import { getUser } from "helpers/user_helper"

const userListColumns = (enableCallback, deleteCallback) => [
  {
    dataField: "img",
    text: "",
    formatter: (cellContent, user) => (
      <>
        <div className="avatar-xs">
          <span className="avatar-title rounded-circle">
            {user.firstName.charAt(0)}
          </span>
        </div>
      </>
    ),
  },
  {
    text: "Name",
    dataField: "name",
    sort: true,
    formatter: (cellContent, user) => (
      <>
        <h5 className="font-size-14 mb-1">
          <Link to="#" className="text-dark">
            {user.firstName} {user.lastName}
          </Link>
        </h5>
        <p className="text-muted mb-0">{user.department}</p>
      </>
    ),
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
  },
  {
    text: "Roles",
    dataField: "tags",
    sort: true,
    formatter: (cellContent, user) => (
      <>
        {user.roles.map((role, key) => (
          <Link
            to="#"
            className="badge badge-soft-primary font-size-11 m-1"
            key={"_role_" + key}
          >
            {role}
          </Link>
        ))}
      </>
    ),
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "Quick actions",
    formatter: (cellContent, user) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        {
          getUser()._id != user._id &&
          (
            <>
              <li className="list-inline-item px-2">
                <Link to="#" id={"enable" + user._id} onClick={() => enableCallback(user)}>
                  <i className={ user.isActive ? "bx bxs-user-circle" : "bx bx-user-circle"} />
                  <UncontrolledTooltip placement="left" target={"enable" + user._id}>
                    { user.isActive ? "Disable user" : "Enable user" }
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li className="list-inline-item px-2">
                <Link to="#" id={"delete" + user._id} onClick={() => deleteCallback(user)}>
                  <i className="bx bx-trash" />
                  <UncontrolledTooltip placement="left" target={"delete" + user._id}>
                    Delete user
                  </UncontrolledTooltip>
                </Link>
              </li>
            </>
          )
        }
      </ul>    ),
  }
]

export default userListColumns
