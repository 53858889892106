import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Form,
  Label,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { map } from "lodash"
import classnames from "classnames"

class Previews extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: 0
    }
  }

  getComponentForPreview = (preview) => {
    const images = ['gif', 'png', 'jpg', 'jpeg', 'bmp']
    const videos = ['mp4', 'mov', 'avi']
    const binary = ['pdf']

    const ext =  preview.name.split('.').pop();

    if(images.includes(ext)) {
      return (<img src={preview.link} style={{width: '100%'}} />)
    } else if(videos.includes(ext)) {
      return (
        <video controls style={{width: '100%'}}>Your browser does not support the &lt;video&gt; tag.
          <source src={preview.link} />
        </video>
      )
    } else if(binary.includes(ext)) {
      return (<p>Click <a href={preview.link} target="_blank">here</a> to download.</p>)
    } else {
      return (<p>This file type is not supported by the system.</p>)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    
    const { name, email } = e.target

    if(name.value == undefined || name.value == "") {
      window.alert("Name is a required field and cannot be empty")
    } else if (email.value == undefined || email.value == "") {
      window.alert("Email is a required field and cannot be empty")
    } else {
      this.props.onSend({
        name: name.value,
        email: email.value,
        preview: this.props.previews[this.state.activeTab]._id
      })
    }
  }

  render() {
    const { previews } = this.props

    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Previews</CardTitle>
            { (previews.length == 0)
              ? <span className="text-muted">Nothing to see here... yet.</span>
              : <>
                <Nav tabs className="nav-tabs-custom nav-justified">
                  {
                    map(previews, (preview, index) => (
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === index,
                          })}
                          onClick={() => {
                            this.setState({ activeTab: index })
                          }}
                        >
                          <span className="d-none d-sm-block">{preview.name}</span>
                        </NavLink>
                      </NavItem>
                    ))
                  }
                </Nav>
                <TabContent
                  activeTab={this.state.activeTab}
                  className="p-3 text-muted"
                >
                  {
                    map(previews, (preview, index) => (
                      <TabPane tabId={index}>
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              {this.getComponentForPreview(preview)}
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                    ))
                  }
                </TabContent>
                <br />
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col>
                      <p>Email this preview to...</p>
                    </Col>
                  </Row>                
                  <Row>
                    <Col mg={6}>
                      <div className="form-floating mb-3">
                        <Input
                          type="text"
                          className="form-control"
                          id="floatingnameInput"
                          placeholder="Enter Name"
                          name="name"
                        />
                        <Label htmlFor="floatingnameInput">Name</Label>
                      </div>
                    </Col>
                    <Col mg={6}>
                      <div className="form-floating mb-3">
                      <input
                          type="email"
                          className="form-control"
                          id="floatingemailInput"
                          placeholder="Enter Email address"
                          name="email"
                        />
                        <Label htmlFor="floatingemailInput">
                          Email address
                        </Label>
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <Button type="submit" color="primary" className="w-md">
                      Submit
                    </Button>
                  </div>
                </Form>
              </>
            }
          </CardBody>
        </Card>
      </React.Fragment>    
    ) 
  }
}

Previews.propTypes = {
  previews: PropTypes.array,
  onSend: PropTypes.func
}

export default Previews
