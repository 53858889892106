import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { Component } from "react"
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"
import { forgotPassword } from "networking/api"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// import images
import profile from "../../assets/images/profile-img.png"

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resetRequested: false
    }
  }

  // handleValidSubmit
  handleValidSubmit = async (event, values) => {
    this.setState({ resetRequested: true })
    await forgotPassword({ email: values.email })
  }

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>
            Forgot Password - Creative Display Content Request System
          </title>
        </MetaTags>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Recover your password</h5>
                          <p>Enter your account email address to continue.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="p-2" style={{'marginTop': '1.5rem'}}>
                      {this.state.resetRequested ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          If an account with the specified email address is found, you will receive instructions via email to complete your password reset.
                        </Alert>
                      ) : null}

                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email Address"
                            className="form-control"
                            placeholder="Enter email address"
                            type="email"
                            required
                          />
                        </div>
                        <div className="text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            disabled={this.state.resetRequested}
                          >
                            Reset
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Go back to{" "}
                    <a href="login" className="fw-medium text-primary">
                      Login
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object
}

export default ForgetPasswordPage;
