import React from "react"
import { UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"
import Moment from "react-moment"
import statusMap from "./statusColorMap"


const workOrderListColumns = (statusCallback, commentCallback, assignCallback) => [
  {
    text: "Title",
    dataField: "title",
    sort: true,
    formatter: (cellContent, workOrder) => (
      <>
        <h5 className="font-size-14 mb-1">
          <Link to={'/work-orders/' + workOrder._id} className="text-dark">
            {workOrder.title}
          </Link>
        </h5>
        <p className="text-muted mb-0 font-size-10">Submitted by {workOrder.submittedBy.firstName} {workOrder.submittedBy.lastName}</p>
      </>
    ),
  },
  {
    text: "Due date",
    dataField: "dueAt",
    sort: true,
    formatter: (cellContent, workOrder) => (
      <Moment format="MMMM D, YYYY">{workOrder.dueAt}</Moment>
    )
  },
  
  {
    text: "Status",
    dataField: "status",
    sort: true,
    formatter: (cellContent, workOrder) => (
      <>
        <div className={"badge badge-soft-" + statusMap(workOrder.status)}>{workOrder.status.replace(/_/g, ' ')}</div>
      </>
    ),
  },
  {
    dataField: "assignedTo",
    isDummyField: true,
    text: "Assigned to",
    formatter: (cellContent, workOrder) => (
      (workOrder.assignedTo) ?
        (<div className="badge bg-primary">{workOrder.assignedTo.firstName} {workOrder.assignedTo.lastName}</div>) :
        (<div className="badge bg-light">Unassigned</div>)
    )
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "Quick actions",
    formatter: (cellContent, workOrder) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item px-2">
          <Link to="#" id={"status" + workOrder._id} onClick={() => statusCallback(workOrder)}>
            <i className="bx bx-bolt-circle" />
            <UncontrolledTooltip placement="left" target={"status" + workOrder._id}>
              Update status
            </UncontrolledTooltip>
          </Link>
        </li>
        <li className="list-inline-item px-2">
          <Link to="#" id={"message" + workOrder._id} onClick={() => commentCallback(workOrder)}>
            <i className="bx bx-message-square-dots" />
            <UncontrolledTooltip placement="left" target={"message" + workOrder._id}>
              Add comment
            </UncontrolledTooltip>
          </Link>
        </li>
        { !workOrder.assignedTo &&
          (
            <li className="list-inline-item px-2">
              <Link to="#" id={"profile" + workOrder._id} onClick={() => assignCallback(workOrder)}>
                <i className="bx bx-user-check" />
                <UncontrolledTooltip placement="left" target={"profile" + workOrder._id}>
                  Self-assign
                </UncontrolledTooltip>
              </Link>
            </li>
          )
        }
      </ul>
    ),
  },
]

export default workOrderListColumns
