const availableStatusMap = (workOrder) => {
  var statuses = []

  switch(workOrder.status) {
    case 'received':
      if(workOrder.assignedTo) {
        statuses.push({
          title: 'In Progress',
          value: 'in_progress'
        })
      }
      break
    case 'in_progress':
      statuses.push({
        title: 'Artwork Ready',
        value: 'artwork_ready'
      })
      break
    case 'artwork_ready':
      statuses.push({
        title: 'Approved',
        value: 'approved'
      }, {
        title: 'Revision',
        value: 'revision'
      })
      break
    case 'approved':
      statuses.push({
        title: 'Complete',
        value: 'complete'
      })
      break
    case 'revision':
      statuses.push({
        title: 'In Progress',
        value: 'in_progress'
      })
      break
    case 'hold':
      statuses.push({
        title: 'In Progress',
        value: 'in_progress'
      })
      break
    default:
      break
  }

  statuses.push({
    title: 'Hold',
    value: 'hold'
  }, {
    title: 'Cancelled',
    value: 'cancelled'
  })

  return statuses
}

export default availableStatusMap
