import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardTitle, Table } from "reactstrap"
import { map } from "lodash"

const LocationList = ({ locations }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Locations</CardTitle>

        <div className="table-responsive">
          <Table className="table align-middle table-nowrap">
            <tbody>
              {map(locations, (location, k) => (
                <tr key={"_member_" + k}>
                  <td>
                    <h5 className="font-size-14 m-0">
                      {location.name}
                    </h5>
                  </td>
                  <td>
                    <div>
                      
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  )
}

LocationList.propTypes = {
  locations: PropTypes.array,
}

export default LocationList
