import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
} from "reactstrap"
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
import { getLocations, getWorkOrder, patchWorkOrder } from "networking/api"
import { isEmpty } from "lodash"

class WorkOrderEdit extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()
    this.state = {
      workOrder: {},
      customer: '',
      details: '',
      dueDate: new Date(),
      startDate: new Date(),
      error: '',
      loading: false,
      locations: [],
      hasLoadedExistingLocations: false,
      availableLocations: []
    }
    this.dueDateChanged.bind(this)
    this.startDateChange.bind(this)
    this.handleLocationsUpdated.bind(this)
    this.submitForm.bind(this)
    this.submitWorkOrder.bind(this)
  }

  componentDidMount() {
    this.loadData()
  }

  loadData = async() => {
    const id = this.props.match.params.id

    try {
      const availableLocations = await getLocations() 
      const workOrder = await getWorkOrder(id)

      this.setState({ 
        availableLocations,
        workOrder: workOrder,
        customer: workOrder.title,
        details: workOrder.details,
        dueDate: new Date(workOrder.dueAt),
        startDate: new Date(workOrder.startsAt),
        locations: workOrder.locations.map(l => l._id)
      })
    } catch(error) {
      this.props.history.push("/work-orders")
    }
  }

  dueDateChanged = (date) => {
    this.setState({
      dueDate: date
    })
  };

  startDateChange = (date) => {
    this.setState({
      startDate: date,
    })
  };

  handleLocationsUpdated = (locations) => {
    if(locations) {
      this.setState({ locations: locations.map(function(l) { return l.value }) })
    } else {
      this.setState({ locations: [] })
    }
  };

  submitForm = () => {
    this.form.current.handleSubmit()
  }

  submitWorkOrder = async (event, data) => {
    this.setState({ loading: true })

    const id = this.props.match.params.id
    
    try {
      await patchWorkOrder(id, {
        title: data.title,
        details: data.details,
        startsAt: this.state.startDate.toISOString(),
        dueAt: this.state.dueDate.toISOString(),
        locations: this.state.locations
      })

      this.props.history.push(`/work-orders/${id}`)
    } catch(error) {
      console.error(error)
    }
  }

  initialLocationsList = () => {
    return isEmpty(this.state.workOrder) 
      ? [] 
      : this.state.workOrder.locations.map(l => { return { label: l.name, value: l._id }})
  }

  render() {
    const initialLocations = this.initialLocationsList()

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
              Edit Work Order - Creative Display Content Request System
            </title>
          </MetaTags>
          <Container fluid>
          <Row>
              <Col xs={12}>
                <div className="page-title-box">
                  <h4 className="mb-0 font-size-18">Edit Work Order</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.submitWorkOrder} ref={this.form}>
                      {this.props.error && this.props.error ? (
                        <Alert color="danger">{this.props.error}</Alert>
                      ) : null}

                      <AvGroup className="mb-4" row>
                          <Label
                            for="title"
                            className="col-form-label col-lg-2"
                          >
                            <b>Work Order Title</b>
                          </Label>
                          <Col lg="10">
                            <AvInput
                              id="title"
                              name="title"
                              type="text"
                              className="form-control"
                              placeholder="Enter the title of the work order or a customer name"
                              value={this.state.customer}
                              required
                            />
                          </Col>
                      </AvGroup>                      
                      <AvGroup className="mb-4" row>
                        <Label
                          for="details"
                          className="col-form-label col-lg-2"
                        >
                          Work Order Details
                        </Label>
                        <Col lg="10">
                          <AvInput
                            type="textarea"
                            className="form-control"
                            id="details"
                            name="details"
                            rows="5"
                            value={this.state.details}
                            placeholder="Enter details for this work order"
                          />
                        </Col>
                      </AvGroup>

                      <AvGroup className="mb-4" row>
                        <Label 
                          className="col-form-label col-lg-2"
                          for="dueDate"
                        >
                          <b>Due Date</b>
                        </Label>
                        <Col lg="10">
                          <DatePicker
                            className="form-control"
                            onChange={this.dueDateChanged}
                            id="dueDate"
                            name="dueDate"
                            dateFormat="yyyy-MM-dd"
                            openToDate={this.state.dueDate}
                            value={this.state.dueDate.toDateString()}
                            required
                          />  
                        </Col>
                      </AvGroup>

                      <AvGroup className="mb-4" row>
                        <Label 
                          className="col-form-label col-lg-2"
                          for="startDate"
                        >
                          <b>Start Date</b>
                        </Label>
                        <Col lg="10">
                          <DatePicker
                            className="form-control"
                            onChange={this.startDateChange}
                            id="startDate"
                            name="startDate"
                            dateFormat="yyyy-MM-dd"
                            openToDate={this.state.startDate}
                            value={this.state.startDate.toDateString()}
                            required
                          />                           
                        </Col>
                      </AvGroup>
                      <AvGroup className="mb-4" row>
                        <Label
                          for="locations"
                          className="col-form-label col-lg-2"
                        >
                          <b>Locations</b>
                        </Label>
                        <Col lg="10">
                          { !isEmpty(initialLocations) && (
                            <Select
                              isMulti={true}
                              onChange={this.handleLocationsUpdated}
                              options={this.state.availableLocations.map(l => { return { label: l.name, value: l._id }})}
                              classNamePrefix="select2-selection"
                              name="locations"
                              id="locations"
                              defaultValue={initialLocations}
                              required
                            />
                          )}                          
                        </Col>
                      </AvGroup>               
                    </AvForm>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        <Button color="primary" disabled={this.state.loading} onClick={this.submitForm}>
                          Save Changes
                        </Button>
                      </Col>
                    </Row>                   
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

WorkOrderEdit.propTypes = {
  history: PropTypes.object
}

export default withRouter(WorkOrderEdit)
