import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Button,
} from "reactstrap"
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
import FileUpload from "./Reusable/fileUpload"
import { getLocations, postWorkOrder } from "../../networking/api"
import { isAdmin } from "../../helpers/user_helper"
const moment = require('moment-business-days')

class WorkOrderCreate extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()

    this.state = {
      customer: '',
      details: '',
      dueDate: this.getMinimumDate(),
      startDate: this.getMinimumDate(),
      selectedFiles: [],
      error: '',
      loading: false,
      locations: [],
      availableLocations: []
    }
    this.dueDateChanged.bind(this)
    this.startDateChange.bind(this)
    this.handleAcceptedFiles.bind(this)
    this.handleLocationsUpdated.bind(this)
    this.submitForm.bind(this)
    this.submitWorkOrder.bind(this)
  }

  componentDidMount() {
    this.loadLocations()
  }

  loadLocations = async() => {
    try {
      const response = await getLocations()
      this.setState({ availableLocations: response.map(l => { return { label: l.name, value: l._id } })})
    } catch(error) {
      console.error(error)
    }
  }

  dueDateChanged = (date) => {
    this.setState({
      dueDate: date
    })
  };

  startDateChange = (date) => {
    this.setState({
      startDate: date,
    })
  };

  getMinimumDate = () => {
    return isAdmin() ? moment().businessAdd(1).toDate() : moment().businessAdd(5).toDate()
  }

  handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  };

  handleLocationsUpdated = (locations) => {
    if(locations) {
      this.setState({ locations: locations.map(function(l) { return l.value }) })
    } else {
      this.setState({ locations: [] })
    }
  };

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  };

  submitForm = () => {
    this.form.current.handleSubmit()
  }

  submitWorkOrder = async (event, data) => {
    this.setState({ loading: true })
    
    try {
      await postWorkOrder({
        title: data.title,
        details: data.details,
        startsAt: this.state.startDate.toISOString(),
        dueAt: this.state.dueDate.toISOString(),
        locations: this.state.locations,
        files: this.state.selectedFiles
      })

      this.props.history.push("/work-orders")
    } catch(error) {
      console.error(error)
    }
  }

  filterWeekends = (date) => {
    const day = date.getDay()
    return day !== 0 && day !== 6;
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
              Create Work Order - Creative Display Content Request System
            </title>
          </MetaTags>
          <Container fluid>
          <Row>
              <Col xs={12}>
                <div className="page-title-box">
                  <h4 className="mb-0 font-size-18">New Work Order</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.submitWorkOrder} ref={this.form}>
                      {this.props.error && this.props.error ? (
                        <Alert color="danger">{this.props.error}</Alert>
                      ) : null}

                      <AvGroup className="mb-4" row>
                          <Label
                            for="title"
                            className="col-form-label col-lg-2"
                          >
                            <b>Work Order Title</b>
                          </Label>
                          <Col lg="10">
                            <AvInput
                              id="title"
                              name="title"
                              type="text"
                              className="form-control"
                              placeholder="Enter the title of the work order or a customer name"
                              required
                            />
                          </Col>
                      </AvGroup>                      
                      <AvGroup className="mb-4" row>
                        <Label
                          for="details"
                          className="col-form-label col-lg-2"
                        >
                          Work Order Details
                        </Label>
                        <Col lg="10">
                          <AvInput
                            type="textarea"
                            className="form-control"
                            id="details"
                            name="details"
                            rows="5"
                            placeholder="Enter details for this work order"
                          />
                        </Col>
                      </AvGroup>

                      <AvGroup className="mb-4" row>
                        <Label 
                          className="col-form-label col-lg-2"
                          for="dueDate"
                        >
                          <b>Due Date</b>
                        </Label>
                        <Col lg="10">
                          <DatePicker
                            className="form-control"
                            selected={this.state.dueDate}
                            onChange={this.dueDateChanged}
                            id="dueDate"
                            name="dueDate"
                            dateFormat="yyyy-MM-dd"
                            value={this.state.dueDate.toDateString()}
                            minDate={this.getMinimumDate()}
                            filterDate={this.filterWeekends}
                            required
                          />  
                        </Col>
                      </AvGroup>

                      <AvGroup className="mb-4" row>
                        <Label 
                          className="col-form-label col-lg-2"
                          for="startDate"
                        >
                          <b>Start Date</b>
                        </Label>
                        <Col lg="10">
                          <DatePicker
                            className="form-control"
                            selected={this.state.startDate}
                            onChange={this.startDateChange}
                            id="startDate"
                            name="startDate"
                            dateFormat="yyyy-MM-dd"
                            value={this.state.startDate.toDateString()}
                            required
                          />                           
                        </Col>
                      </AvGroup>

                      <AvGroup className="mb-4" row>
                        <Label
                          for="locations"
                          className="col-form-label col-lg-2"
                        >
                          <b>Locations</b>
                        </Label>
                        <Col lg="10">
                          <Select
                            isMulti={true}
                            onChange={this.handleLocationsUpdated}
                            options={this.state.availableLocations}
                            classNamePrefix="select2-selection"
                            name="locations"
                            id="locations"
                            required
                          />
                        </Col>
                      </AvGroup>
                    </AvForm>

                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-2">
                        Attached Files
                      </Label>
                      <Col lg="10">
                        <Form>
                          <FileUpload onFilesSelected={this.handleAcceptedFiles} />
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {this.state.selectedFiles.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>
                        </Form>
                      </Col>
                    </FormGroup>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        <Button color="primary" disabled={this.state.loading} onClick={this.submitForm}>
                          Create Work Order
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

WorkOrderCreate.propTypes = {
  history: PropTypes.object
}

export default withRouter(WorkOrderCreate)
