import React, { Component } from "react"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import locationListColumns from "./locationListColumns"
import { getLocations } from "../../networking/api"

class LocationList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      locations: [],
    }
  }

  componentDidMount() {
    this.loadData()
  }

  loadData = async() => {
    try {
      let response = await getLocations()
      this.setState({ 
        locations: response, 
        tableLocations: response 
      })
    } catch(error) {
      console.error(error)
    }
  }

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (type, { page, searchText }) => {
    const { locations } = this.state
    this.setState({
      tableLocations: locations.filter((location) =>
        Object.keys(location).some(
          (key) =>
            typeof location[key] === "string" &&
            location[key].toLowerCase().includes(searchText.toLowerCase())
        )
      ),
    })
  };

  render() {
    const { tableLocations } = this.state
    const { SearchBar } = Search

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
          <title>
              Locations - Creative Display Content Request System
            </title>
          </MetaTags>
          <Container fluid>
            <Row>
              <Col xs={12}>
                <div className="page-title-box">
                  <h4 className="mb-0 font-size-18">Locations</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>                    
                    <ToolkitProvider
                      keyField="_id"
                      data={tableLocations || []}
                      columns={locationListColumns()}
                      bootstrap4
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col xs="8">
                              <div className="search-box ms-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col className="text-end">
                              <Link to="/locations/new" className="btn btn-outline-primary" history={this.props.history}>
                                New Location
                              </Link>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  responsive
                                  remote
                                  bordered={false}
                                  striped={false}
                                  classes={
                                    "table align-middle table-nowrap table-hover"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                  onTableChange={this.handleTableChange}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>                      
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(LocationList)
