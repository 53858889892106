const key = 'authUser'

export const setUser = (user) => {
  localStorage.setItem(key, JSON.stringify(user))
}

export const getUser = () => {
  return JSON.parse(localStorage.getItem(key))
}

export const isAdmin = () => {
  return getUser().roles.includes('admin')
}

export const removeUser = () => {
  localStorage.removeItem(key)
}
