import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardTitle, Table } from "reactstrap"
import { map } from "lodash"
import { getUser } from "helpers/user_helper"

const TeamMembers = ({ team, selfAssign }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Team Members</CardTitle>
        <div className="table-responsive">
          <Table className="table align-middle table-nowrap">
            <tbody>
              {map(team, (member, k) => (
                <tr key={"_member_" + k}>
                  <td>
                    <h5 className="font-size-14 m-0">
                      {member.firstName} {member.lastName} <span className="badge badge-soft-primary" style={{marginLeft: '10px'}}>{(member.department === "none") ? "admin" : member.department}</span>
                    </h5>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        { getUser().department.toLowerCase() == 'creative' &&
          <button className="btn btn-primary" onClick={selfAssign}>Assign to Me</button>
        }
      </CardBody>
    </Card>
  )
}

TeamMembers.propTypes = {
  selfAssign: PropTypes.func,
  team: PropTypes.array
}

export default TeamMembers
